<template>
	<b-container fluid>
		<bo-page-title />
		<section id="contact">
			<b-tabs v-if="isList" v-model="openTab">
				<b-tab title="Inbox">
					<b-card no-body>
						<template #header>
							<div class="d-flex align-items-center">
								<h5 class="card-title">Inbox</h5>

								<div class="ml-3">
									<b-button class="btn-rounded" size="sm" :variant="filterByStatus=='all'?'info':'outline-info'"
										:to="{name:$route.name, query:Object.assign({},$route.query||{}, {page:1, filterByStatus:'all'})}">
										All <span>({{ countData.all || 0 }})</span>
									</b-button>
									<b-button class="btn-rounded ml-1" size="sm" :variant="filterByStatus=='unread'?'info':'outline-info'"
										:to="{name:$route.name, query:Object.assign({}, $route.query||{}, {page:1, filterByStatus:'unread'})}">
										Unread <span>({{ countData.unread || 0 }})</span>
									</b-button>
									<b-button class="btn-rounded ml-1" size="sm" :variant="filterByStatus=='read'?'info':'outline-info'"
										:to="{name:$route.name, query:Object.assign({}, $route.query||{}, {page:1, filterByStatus:'read'})}">
										Read <span>({{ countData.read || 0 }})</span></b-button>
								</div>
							</div>
						</template>
						<b-card-body>
							<b-row>
								<b-col md>
								</b-col>
								<b-col md="auto">
									<div class="d-flex">
										 <b-form @submit.prevent="doFilter()" class="d-flex">
											<b-input-group>
											<b-form-input v-model="filter.search" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
											<b-input-group-append>
												<b-button @click="doFilter()" variant="success"><i class="fas fa-search"></i></b-button>
											</b-input-group-append>
											</b-input-group>
											<b-button
												class="ml-1 d-inline-flex align-items-center"
												variant="outline-success"
												@click="doReset()"
											>Reset</b-button>
										</b-form>
									</div>
								</b-col>
							</b-row>
						</b-card-body>
						<b-table :fields="inboxFields" :items="(data.data||[])" show-empty>
							<template #cell(num)="data">{{ data.index += 1 }}</template>
							<template #cell(sender)="data">
								<div>{{ data.item.sfc_fullname }}</div>
								<small class="d-inline-block">
									<i class="ti-email mr-1"></i>
									<span>{{ data.item.sfc_email }}</span>
								</small><br/>
								<small class="d-inline-block">
									<i class="icon-phone mr-1"></i>
									<span>{{ data.item.sfc_phone }}</span>
								</small>
							</template>
							<template #cell(subject)="data">
								<div>{{ data.item.sfc_subject }}</div>
								<small>{{ data.item.sfc_message }}</small>
							</template>
							<template #cell(date)="data">
								<div>{{ data.item.sfc_date | moment('LLL') }}</div>
							</template>
							<template #cell(status)="data">
								<b>{{ data.item.sfc_is_active === 'D' ? 'Unread' : 'Read' }}</b>
							</template>
							<template #cell(actions)="data">
								<b-button variant="outline-info" class="btn-icon" v-b-tooltip.hover="'Read Message'"
									:to="{ name: routeName, params: { pageSlug: data.item.sfc_id } }"><i class="icon-eye"></i></b-button>
							</template>
							<template #empty>
								<div class="text-center">
									<b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
									<h4 align="center"><span v-if="Object.keys(filter).length">no data shown</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
								</div>
							</template>
						</b-table>
						<template #footer>
							<b-pagination v-if="data.total > data.per_page" class="mb-0 justify-content-end" v-model="pageNo"
								:per-page="data.per_page" :total-rows="data.total" />
						</template>
					</b-card>
				</b-tab>
				<b-tab title="Page Settings" v-if="moduleRole('updateHero') || moduleRole('malaccaCare')" ref="careTab">
					<bo-card title="Hero Image" v-if="moduleRole('updateHero')">
						<b-row>
							<b-col md="4">
								<bo-card-img title="Hubungi Kami" :src="uploader(heroImage.ssf_file, '/assets/images/no-data.jpg')">
									<template #buttons>
										<b-button variant="secondary" pill class="btn-icon" v-b-tooltip.hover="'Update'"
											@click="changeHero">
											<i class="fas fa-pencil-alt" />
										</b-button>
									</template>
								</bo-card-img>
							</b-col>
						</b-row>
						<b-modal 
							v-model="heroEditorOpen"
							:title="'Update Hero Image '+ pageTitle"
							size="lg"
							hide-footer
							body-class="p-0"
						>
						<validation-observer
							v-slot="{ handleSubmit }" ref="VFormHero"
						>
							<b-form @submit.prevent="handleSubmit(submitHero)">
							<b-card-body>
								<b-row>
								<b-col md="6">
									<div class="file-uploader">
									<label>Hero Image Desktop: </label>
									<Uploader v-model="heroImage.ssf_file" type="hero-contact"/>
									<VValidate 
										name="Hero Image Desktop" 
										v-model="heroImage.ssf_file"
										:rules="validationHeroImage.ssf_file" 
									/>
									</div>
									
									<div class="file-uploader">
									<label>Hero Image Mobile: </label>
									<Uploader v-model="heroImage.ssf_file_mobile" type="hero-contact-mobile" use-preview />
									<VValidate 
										name="Hero Image Mobile" 
										v-model="heroImage.ssf_file_mobile"
										:rules="validationHeroImage.ssf_file_mobile" 
									/>
									</div>
								</b-col>
								<b-col md="6">
									<b-card class="border mb-0">
									<template #header>
										<h5 class="card-title">Content (ID)</h5>
									</template>
									<b-form-group label-for="heroTitleId">
										<template #label>Title <strong class="text-danger">*</strong></template>
										<b-form-input id="heroTitleId" v-model="heroImage.ssf_title_id" @input="autoFill('ssf_alt_mobile_id','ssf_alt_id','ssf_title_id')"
										@keyup="removeWildChar" @keydown="removeWildChar"/>
										<VValidate 
										name="Content ID" 
										v-model="heroImage.ssf_title_id"
										:rules="{...validationHeroImage.ssf_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
										/>
									</b-form-group>

									<b-form-group label-for="altId">
										<template #label>Alt Image <strong class="text-danger">*</strong></template>
										<b-form-input id="altId" v-model="heroImage.ssf_alt_id" 
										@keyup="removeWildChar" @keydown="removeWildChar"/>
										<VValidate 
										name="Alt Image Id" 
										v-model="heroImage.ssf_alt_id"
										:rules="{...validationHeroImage.ssf_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
										/>
									</b-form-group>
									
									<b-form-group label-for="altIdM">
									<template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
										<b-form-input id="altIdM" v-model="heroImage.ssf_alt_mobile_id" @keyup="removeWildChar" @keydown="removeWildChar"/>
										<VValidate 
										name="Alt Image Mobile ID" 
										v-model="heroImage.ssf_alt_mobile_id"
										:rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
									/>
									</b-form-group>
									
									</b-card>
									<b-card class="border mb-0">
									<template #header>
										<h5 class="card-title">Content (EN)</h5>
									</template>
									<b-form-group label-for="heroTitleEn">
										<template #label>Title <strong class="text-danger">*</strong></template>
										<b-form-input id="heroTitleEn" @input="autoFill('ssf_alt_mobile_en','ssf_alt_en','ssf_title_en')" v-model="heroImage.ssf_title_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
										<VValidate 
										name="Content EN" 
										v-model="heroImage.ssf_title_en"
										:rules="{...validationHeroImage.ssf_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
										/>
									</b-form-group>

									<b-form-group label-for="altEN">                          
										<template #label>Alt Image <strong class="text-danger">*</strong></template>
										<b-form-input id="altEN" v-model="heroImage.ssf_alt_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
										<VValidate 
										name="Alt Image En" 
										v-model="heroImage.ssf_alt_en"
										:rules="{...validationHeroImage.ssf_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
										/>
									</b-form-group>
									
									<b-form-group label-for="altEnM">
										<template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
										<b-form-input id="altIEn" v-model="heroImage.ssf_alt_mobile_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
										<VValidate 
										name="Alt Image Mobile EN" 
										v-model="heroImage.ssf_alt_mobile_en"
										:rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
										/>
									</b-form-group>
									</b-card>
								</b-col>
								</b-row>
							</b-card-body>
							<b-card-footer>
								<div class="text-right">
								<b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
								</div>
							</b-card-footer>
							</b-form>
						</validation-observer>
						</b-modal>
					</bo-card>
					<validation-observer v-slot="{ handleSubmit }" ref="VFormCare" v-if="moduleRole('malaccaCare')">
						<b-form @submit.prevent="handleSubmit(doSubmitCareCenter)">
							<bo-card title="Malacca Care Center">
								<b-row align-v="start">
									<b-col md="6">
										<div class="main-card care-center">
											<div class="main-card-body">
												<b-row align-v="center">
													<b-col md="4" lg="3">
														<b-img src="/assets/images/malacca-care-white.png" alt="" fluid-grow
															class="care-center__img" />
													</b-col>
													<b-col md="8" lg="9">
														<div class="care-center-head">
															<h3 class="care-center__title">Malacca Care Center</h3>
															<span class="care-center__post-title">{{careCenter.ssc_desc_id}}</span>
														</div>
														<div class="care-center__number">
															<i class="icon-phone"></i>
															<span>{{careCenter.ssc_title_id}}</span>
														</div>
													</b-col>
												</b-row>
											</div>
										</div>
									</b-col>
									<b-col md="6">
										<b-card class="border mb-0">
											<template #header>
												<h5 class="card-title">Manage Content</h5>
											</template>
											<b-form-group label-for="careCenterDescId">
												<template #label>Description (ID)</template>
												<b-form-textarea v-model="careCenter.ssc_desc_id" id="careCenterDescId" rows="4" @keyup="removeWildChar" @keydown="removeWildChar"/>
												<VValidate name="Description ID" v-model="careCenter.ssc_desc_id"
													:rules="{...careCenterValidation.ssc_desc_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
											</b-form-group>
											<b-form-group label-for="careCenterDescEn">
												<template #label>Description (EN)</template>
												<b-form-textarea v-model="careCenter.ssc_desc_en" id="careCenterDescEn" rows="4" @keyup="removeWildChar" @keydown="removeWildChar"/>
												<VValidate name="Description EN" v-model="careCenter.ssc_desc_en"
													:rules="{...careCenterValidation.ssc_desc_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
											</b-form-group>
											<b-form-group label-for="careCenterPhone">
												<template #label>Phone Number</template>
												<b-input-group>
													<template #prepend>
														<b-input-group-text><i class="icon-phone"></i>
														</b-input-group-text>
													</template>
													<b-form-input type="tel" v-model="careCenter.ssc_title_id" id="careCenterPhone" maxlength="16" />
												</b-input-group>
												<VValidate name="Mobile Number" v-model="careCenter.ssc_title_id"
													:rules="{required:1, regex:/^[0-9\+ ]+$/ }" />
											</b-form-group>
										</b-card>
									</b-col>
								</b-row>
								<template #footer>
									<div class="text-right">
										<b-button type="submit" variant="info" class="btn-rounded">Save Changes
										</b-button>
									</div>
								</template>
							</bo-card>
						</b-form>
					</validation-observer>
				</b-tab>
				<b-tab title="SEO Settings" v-if="moduleRole('updateSEO')">
					<b-card no-body>
						<b-card-header>
							<b-card-title title-tag="h5">Contact SEO Settings</b-card-title>
						</b-card-header>
						<validation-observer v-slot="{ handleSubmit }" ref="VFormSEO">
							<b-form @submit.prevent="handleSubmit(submitSEO)">
								<b-card-body>
									<b-row>
										<b-col lg="6">
											<b-form-group label-for="homeMetaTitle">
												<template #label>
													Meta Title (ID)
													<b-badge variant="info" pill class="help-badge"
														v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
														<i class="fas fa-question"></i>
													</b-badge>
												</template>
												<b-form-input id="homeMetaTitle" placeholder="Meta Title"
													v-model="seoPageSetting.sss_meta_title_id"/>
												<VValidate name="Meta Title ID" v-model="seoPageSetting.sss_meta_title_id"
													:rules="{...seoValidation.sss_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
											</b-form-group>
										</b-col>

										<b-col lg="6">
											<b-form-group label-for="homeMetaTitle">
												<template #label>
													Meta Title (EN)
													<b-badge variant="info" pill class="help-badge"
														v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
														<i class="fas fa-question"></i>
													</b-badge>
												</template>
												<b-form-input id="homeMetaTitle" placeholder="Meta Title"
													v-model="seoPageSetting.sss_meta_title_en"/>
												<VValidate name="Meta Title EN" v-model="seoPageSetting.sss_meta_title_en"
													:rules="{...seoValidation.sss_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
											</b-form-group>
										</b-col>
									</b-row>

									<b-row>
										<b-col lg="6">
											<b-form-group label-for="homeMetaDesc">
												<template #label>
													Meta Description (ID)
													<b-badge variant="info" pill class="help-badge"
														v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
														<i class="fas fa-question"></i>
													</b-badge>
												</template>
												<b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description"
													v-model="seoPageSetting.sss_meta_description_id" />
												<VValidate name="Meta Description ID" v-model="seoPageSetting.sss_meta_description_id"
													:rules="{...seoValidation.sss_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
											</b-form-group>
										</b-col>

										<b-col lg="6">
											<b-form-group label-for="homeMetaDesc">
												<template #label>
													Meta Description (EN)
													<b-badge variant="info" pill class="help-badge"
														v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
														<i class="fas fa-question"></i>
													</b-badge>
												</template>
												<b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description"
													v-model="seoPageSetting.sss_meta_description_en" />
												<VValidate name="Meta Description EN" v-model="seoPageSetting.sss_meta_description_en"
													:rules="{...seoValidation.sss_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
											</b-form-group>
										</b-col>
									</b-row>

									<b-row>
										<b-col lg="6">
											<b-form-group label-for="homeMetaTags">
												<template #label>
													Meta Keywords (ID)
													<b-badge variant="info" pill class="help-badge"
														v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
														<i class="fas fa-question"></i>
													</b-badge>
												</template>
												<b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_id"
												:tag-validator="validatorTags"
													placeholder="Type and press enter ..." remove-on-delete tag-variant="primary"
													tag-class="text-white" />
											</b-form-group>
										</b-col>

										<b-col lg="6">
											<b-form-group label-for="homeMetaTags">
												<template #label>
													Meta Keywords (EN)
													<b-badge variant="info" pill class="help-badge"
														v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
														<i class="fas fa-question"></i>
													</b-badge>
												</template>
												<b-form-tags id="homeMetaTags"
												 v-model="seoPageSetting.sss_meta_keyword_en"
												:tag-validator="validatorTags"
													placeholder="Type and press enter ..." remove-on-delete tag-variant="primary"
													tag-class="text-white" />
											</b-form-group>
										</b-col>
									</b-row>
								</b-card-body>
								<b-card-footer>
									<div class="text-right">
										<b-button class="btn-rounded" type="submit" variant="primary">Save Changes
										</b-button>
									</div>
								</b-card-footer>
							</b-form>
						</validation-observer>
					</b-card>
				</b-tab>
			</b-tabs>
			<template v-else>
				<bo-card title="Message Details">
					<b-row class="gutter-2">
						<b-col md="4">
							<div class="contact-info">
								<strong class="contact-info__title">Sender</strong>
								<span class="contact-info__main">{{row.sfc_fullname}}</span>
							</div>
						</b-col>
						<b-col md="4">
							<div class="contact-info">
								<strong class="contact-info__title">Contacts</strong>
								<a :href="'mailto:'+row.sfc_email" class="contact-info__main">
									<i class="ti-email"></i> {{row.sfc_email}}</a>
								<a :href="'tel:'+row.sfc_phone" class="contact-info__main">
									<i class="icon-phone"></i> {{row.sfc_phone||'-'}}
								</a>
							</div>
						</b-col>
						<b-col md="8">
							<div class="contact-info">
								<strong class="contact-info__title">Subject</strong>
								<span class="contact-info__main">{{row.sfc_subject}}</span>
							</div>
						</b-col>
						<b-col md="8">
							<div class="contact-info">
								<strong class="contact-info__title">Message</strong>
								<span class="contact-info__main">{{row.sfc_message}}</span>
							</div>
						</b-col>
					</b-row>
					<template #footer="">
						<div class="text-right">
							<b-button :to="{name: routeName}" variant="info" class="btn-rounded">Back to Inbox
							</b-button>
						</div>
					</template>
				</bo-card>
			</template>
		</section>
	</b-container>
</template>

<script>
	import GlobalVue from '@/libs/Global.vue'
	import BoCardImg from '@/components/backend/BoCardImg.vue'
	// import Gen from '@/libs/Gen'

	let $ = global.jQuery
	let _ = global._

	export default {
		name: 'BoContact',
		extends: GlobalVue,
		components: {
			BoCardImg,
		},
		computed: {
			filterByStatus() {
				return this.$route.query.filterByStatus || "all"
			}
		},
		mounted() {
			setTimeout(() => {
				if (this.$route.query.openCareCenter) this.goToCareCenter() 
			}, 50)
			this.apiGet()
		},
		data() {
			return {
				seoRow: {},
				heroEditorOpen: false,
				inboxFields: [{
					key: 'num',
					label: '#'
				}, 
				'sender', 
				'subject', 
				'date',
				'status',
				'actions', ],
				heroImage: {},
				validationHeroImage: {},
				seoPageSetting: {},
				seoValidation: {},
				careCenter: {},
				careCenterValidation: {},
				test: "hi",
				filter: {},
				countData: {},
				openTab: 0,
			}
		},
		methods: {
			autoFill(to = "", to2 = "", from = ""){
				this.heroImage[to] = this.heroImage[from]
				this.heroImage[to2] = this.heroImage[from]    
			},
			changeHero() {
				this.heroEditorOpen = !this.heroEditorOpen
			},

			doSubmitCareCenter(IdFormCareCenter = 'VFormCare') {
				this.doSubmit(
					"/do/" + this.modulePage,
					_.assign({
						type: 'updateCareCenter'
					}, this.careCenter),
					(type, resp) => {
						if (type == 'success') {
							this.loadingOverlay = true
							this.apiGet()
						} else {
							if (resp.response.status == 422) {
								if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
									var msg = ""
									$.each(Object.keys(resp.response.data), (i, v) => {
										msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
									})
									return this.$swal.fire("Blocked With Validation!", msg)
								} else {
									return this.$swal.fire("Sorry, There is something wrong!", _.values(resp
										.response.data)[0][0])
								}
							} else if (resp.response.status == 400) {
								return this.$swal.fire({
									title: resp.response.data.message,
									icon: 'error',
									confirmButtonText: 'Ok'
								})
							}
						}
					},
					'POST', IdFormCareCenter
				)
			},
			goToCareCenter() {
				this.openTab = 1
			}

		},
		watch: {
			$route() {
				this.apiGet()
			},
			'$route.params.pageSlug': function () {
				this.$set(this, 'filter', {})
			},
			// counter seo
			'seoPageSetting.sss_meta_title_id'(v){
			let el = document.getElementById('Meta Title ID')
			if(el){
				let showcount = document.getElementById('Meta Title ID' + 'count')
				let cErr = document.getElementById('Meta Title ID' + 'count-err')
				if(v.length >= 3 && v.length < 60){
				el.style.display = "none"
				showcount.innerHTML = 60 - v.length + " characters remaining"
				showcount.style.display = "block"
				cErr.style.display = "none"
				}
				else if(v.length < 4){
				cErr.style.display = "none"     
				showcount.style.display = "none"        
				}
				else{
				cErr.style.display = "block"
				showcount.style.display = "none"        
				}
			}
			},
			'seoPageSetting.sss_meta_title_en'(v){
			let el = document.getElementById('Meta Title EN')
			if(el){
				let showcount = document.getElementById('Meta Title EN' + 'count')
				let cErr = document.getElementById('Meta Title EN' + 'count-err')
				if(v.length >= 3 && v.length < 60){
				showcount.innerHTML = 60 - v.length + " characters remaining"
				showcount.style.display = "block"
				cErr.style.display = "none"
				}
				else if(v.length < 4){
				cErr.style.display = "none"     
				showcount.style.display = "none"        
				}
				else{
				cErr.style.display = "block"          
				showcount.style.display = "none"
				}
			}
			},
			'seoPageSetting.sss_meta_description_id'(v){
			let el = document.getElementById('Meta Description ID')
			if(el){
				let showcount = document.getElementById('Meta Description ID' + 'count')
				let cErr = document.getElementById('Meta Description ID' + 'count-err')
				if(v.length >= 3 && v.length < 160){
				showcount.innerHTML = 160 - v.length + " characters remaining"
				showcount.style.display = "block"
				cErr.style.display = "none"
				}
				else if(v.length < 4){
				cErr.style.display = "none"     
				showcount.style.display = "none"        
				}
				else{
				cErr.style.display = "block"
				showcount.style.display = "none"
				}
			}
			},
			'seoPageSetting.sss_meta_description_en'(v){
			let el = document.getElementById('Meta Description EN')
			if(el){
				let showcount = document.getElementById('Meta Description EN' + 'count')
				let cErr = document.getElementById('Meta Description EN' + 'count-err')
				if(v.length >= 3 && v.length < 160){
				showcount.innerHTML = 160 - v.length + " characters remaining"
				showcount.style.display = "block"
				cErr.style.display = "none"
				}
				else if(v.length < 4){
				cErr.style.display = "none"     
				showcount.style.display = "none"        
				}
				else{
				cErr.style.display = "block"
				showcount.style.display = "none"
				}
			}
			},
		}
	}
</script>