<template>
  <b-container fluid>
    <bo-page-title />
    <section id="office">
      <bo-card title="Office Map">
        <template #filters>
          <div class="text-right">
            <b-button
              class="btn-rounded"
              variant="info"
              :disabled="addingPointer"
              @click="addPointer()"
              v-if="moduleRole('add')"
            >
              <i class="fas fa-plus-circle mr-1"></i>
              Add Office
            </b-button>
          </div>
        </template>
        <div class="text-center" v-if="addingPointer">
          <span class="d-block mb-2">Click anywhere on the map to place the pointer. Proceed by clicking "Save Position"</span>
          <b-button
            variant="transparent"
            class="btn-rounded"
            @click="removePointer()"
          >
            Cancel
          </b-button>
          <b-button
            variant="success"
            class="btn-rounded"
            :disabled="!readyToSave"
            @click="savePos()"
          >
            <i class="fas fa-check mr-2"></i>
            Save Position
          </b-button>
        </div>

        <div :class="'map-marking '+(addingPointer ? 'adding-pointer' : '')">
          <a
            href="javascript:;"
            v-for="(address, index) in addressList"
            :key="index"
            :id="address.mo_id+'-office'"
            :class="address.mo_is_main == 'Y' ? 'map-marker map-marker__main' : 'map-marker'"
            :style="address.mo_position"
            v-b-popover.hover.html="{'title': address.mo_city,'content':address.mo_address_id}"
          />
        </div>
      </bo-card>


      <bo-card title="Office List">
        <b-row class="mb-3">
          <b-col md>
            <b-form-row>
              <b-col md="4">
                <v-select :options="officeOptions" v-model="filterProvince" @input="doFilterProvince()" :reduce="v => v.value" />
              </b-col>
            </b-form-row>
          </b-col>
          <b-col md="auto">
            <div class="d-flex">
              <SearchInput :value.sync="filter.search" @search="doFilter"/>
              <b-button
                  class="ml-1 d-inline-flex align-items-center"
                  variant="outline-success"
                  @click="doReset()"
              >Reset</b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="gutter-2">
          <b-col md="4" v-for="(splitCol, index) in splitAddress" :key="index">
            <div class="main-card address-card" v-for="(province, pIndex) in splitCol" :key="province.mof_name">
              <div class="main-card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h3 class="main-card__label">{{ province.mof_name }}</h3>
                </div>
              </div>
              <div 
                class="main-card-body"
                :id="'prov-'+index+pIndex"
              >
                <div class="address-card-item" v-for="address in province.addresses" :key="address.mo_city">
                  <div class="d-flex align-items-center">
                    <h4 class="address-card-item__title mb-0 mr-2">{{ address.mo_city }}</h4>
                    <b-button class="btn-icon"                     
                    variant="outline-warning" v-if="moduleRole('edit')" v-b-tooltip.hover="'Edit Address'" @click="editAddress(address)"><i class="fas fa-pencil-alt" ></i></b-button>
                    <b-button class="btn-icon" v-if="moduleRole('delete')" @click="doDelete(province.addresses,address)" variant="outline-danger" v-b-tooltip.hover="'Delete Address'"><i class="fas fa-trash-alt"></i></b-button>
                  </div>
                  <b-badge :variant="address.mo_is_active == 'N' ? 'danger' : 'success'" class="mb-2">{{address.mo_is_active == 'N' ? 'Inactive' : 'Active'}}</b-badge>
                  <b-badge variant="info" class="mb-2 ml-1" v-if="address.mo_is_main == 'Y'">Main Office</b-badge>
                  
                  <p class="address-card-item__desc" v-html="address.mo_address_id" />
                  <span class="address-card-item__num" v-for="(num, index) in address.mo_phone" :key="index">
                    <i class="icon-phone align-middle mr-1"></i>
                    {{ num }}
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="gutter-2" v-if="splitAddress.length == 0">
          <div class="col-12 text-center my-5">
          <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
          <h4 align="center"><span v-if="Object.keys(filter).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </b-row>

        
        <validation-observer
          v-slot="{ handleSubmit }" ref="VForm"
        >
        <b-modal v-model="modalOffice" :title="mode == 'add' ? 'Add ' : 'Edit ' + 'Office'" size="lg" @ok.prevent="handleSubmit(doSubmitMap)">
          <b-row>
            <b-col md="5">
              <b-form-group label-for="officeProvince">
                <template #label>Province <strong class="text-danger">*</strong></template>
                <v-select id="officeProvince" :options="officeOptionsInput" v-model="row.mo_region" :reduce="v => v.value"  placeholder="Select Province" />
                <VValidate 
                  name="Province" 
                  v-model="row.mo_region"
                  :rules="mrValidation.mo_region" 
                />   
              </b-form-group>
              <b-form-group label-for="officeCity">
                <template #label>City <strong class="text-danger">*</strong></template>
                <b-form-input id="officeCity" @keyup="removeWildChar" @keydown="removeWildChar" v-model="row.mo_city" placeholder="e.g. Jakarta Selatan" />
                <VValidate 
                  name="City" 
                  v-model="row.mo_city"
                  :rules="{...mrValidation.mo_city, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                />   
              </b-form-group>
              <b-form-group label-for="link">
                <template #label>Link Address</template>
                <b-form-input id="link" v-model="row.mo_link_address" placeholder="e.g. Link Google Maps" />
                <VValidate 
                  name="Link" 
                  v-model="row.mo_link_address"
                  :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                />   
              </b-form-group>
              <b-form-group>
                <b-form-checkbox :checked="row.mo_is_main == 'Y' ? true : false" @change="setIsMain(row.mo_is_main)">Set as Main Office Address?</b-form-checkbox>
              </b-form-group>
              <b-form-group v-if="mode !== 'add'">
                <b-button
                  class="btn-rounded"
                  variant="success"
                  @click="editPointer(row.mo_id)"
                >
                  Edit Office Location
                </b-button>
              </b-form-group>
              <b-form-group label="Status" label-for="officeStatus">
                <div class="d-flex">
                  <div class="custom-control custom-radio" style="margin-right: 10px;">
                    <input type="radio" name="radioRow" id="Yes" v-model="row.mo_is_active" value="Y" class="custom-control-input" />
                    <label for="Yes" style="padding-top: 2px;" class="custom-control-label">Active</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" name="radioRow" id="No" v-model="row.mo_is_active" value="N" class="custom-control-input" />
                    <label for="No" style="padding-top: 2px;" class="custom-control-label">Inactive</label>
                  </div>
                </div>
                <VValidate 
                  name="Status" 
                  v-model="row.mo_is_active"
                  :rules="mrValidation.mo_is_active" 
                />   
              </b-form-group>
            </b-col>
            <b-col md="7">
              <b-form-group label-for="officeAddress">
                <template #label>Address ID <strong class="text-danger">*</strong></template>
                <b-form-textarea rows="4" v-model="row.mo_address_id" @keyup="removeWildChar" @keydown="removeWildChar"/>
                <b-form-text>Press enter to make new line</b-form-text>
                <VValidate 
                  name="Address ID" 
                  v-model="row.mo_address_id"
                  :rules="{...mrValidation.mo_address_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                />   
              </b-form-group>
              <b-form-group label-for="officeAddress">
                <template #label>Address EN <strong class="text-danger">*</strong></template>
                <b-form-textarea rows="4" v-model="row.mo_address_en" @keyup="removeWildChar" @keydown="removeWildChar"/>
                <b-form-text>Press enter to make new line</b-form-text>
                <VValidate 
                  name="Address EN" 
                  v-model="row.mo_address_en"
                  :rules="{...mrValidation.mo_address_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                />   
              </b-form-group>
              <b-form-group label-for="officeAddress">
                <template #label>Email</template>
                <b-form-input id="Email" v-model="row.mo_email" placeholder="Email" /> 
                <VValidate 
                  name="Email" 
                  v-model="row.mo_email"
                  rules="email" 
                />   
              </b-form-group>
              
              <b-form-group>
                <template #label>Fax</template>
                <b-form-input id="Fax" v-model="row.mo_fax" placeholder="Fax" /> 
                <VValidate 
                  name="Fax" 
                  v-model="row.mo_fax"
                  :rules="{regex:/^[0-9\+ ]+$/ }"
                />   
              </b-form-group>

              <b-form-group label-for="officeNumbers">
                <template #label>Phone Number<strong class="text-danger">*</strong></template>
                <b-form-row class="align-items-center mb-2">
                  <b-form-tags v-model="row.mo_phone" type="tel" placeholder="Type and press enter ..." remove-on-delete
                    tag-variant="primary" tag-class="text-white"/>
                </b-form-row>
                <VValidate 
                  name="Phone" 
                  v-model="row.mo_phone"
                  :rules="{required : 1, regex:/^[0-9\+ ]+$/ }"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>
        
        </validation-observer>
      </bo-card>


    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'

let $ = global.jQuery
let _ = global._

export default {
  extends: GlobalVue,
  mounted() {
    document.querySelector('.map-marking').addEventListener('click', e => {
      e.stopPropagation()
      if(e.srcElement.className == "map-marking adding-pointer"){
        let el = document.createElement('a'),
        parentEl = document.querySelector('.map-marking')
        el.classList.add('map-marker', 'map-marker__new')
        let parentElRect = e.target.getBoundingClientRect()
        let cursorPos = {
          top: (e.clientY - parentElRect.top) / parentElRect.height * 100+'%',
          left: (e.clientX - parentElRect.left) / parentElRect.width * 100+'%',
        }
        el.style.top = cursorPos.top
        el.style.left = cursorPos.left
        if (this.addingPointer) {
          if (document.querySelectorAll('.map-marker.map-marker__new').length < 1) {
            parentEl.append(el)
          }else {
            let renderedEl = document.querySelector('.map-marker.map-marker__new'),
            renderedElRect = renderedEl.getBoundingClientRect()
            let newCursorPos = {
              top: (e.clientY - parentElRect.top - renderedElRect.height) / parentElRect.height * 100+'%',
              left: (e.clientX - parentElRect.left - (renderedElRect.width / 2)) / parentElRect.width * 100+'%',
            }
            renderedEl.style.top = newCursorPos.top
            renderedEl.style.left = newCursorPos.left
          }
        }
        if (document.querySelectorAll('.map-marker.map-marker__new').length) this.readyToSave = true
      
      }
    })
    setTimeout(()=>{
      let elem = document.getElementById('resetBtn')
      elem.remove()
    },700)
    this.apiGet()
  },
  data() {
    return {
      idKey: 'mo_id',
      addressList:[],
      officeOptions:[],
      officeOptionsInput:[],
      row : {},
      mode : 'add',
      modalOffice : false,
      filterProvince: "all",
      addingPointer: false,
      readyToSave: false,
      editLocate : {}
    }
  },
	computed: {
    splitAddress() {
      let d = [], len = Math.ceil(this.data.length / 3)
      for (let index = 0; index < this.data.length; index += len) {
        d.push(this.data.slice(index, index + len))
      }
      return d
    }
	},
  methods: {
    removePointer(){
      this.readyToSave = false
      this.addingPointer = false
      let elem = document.querySelector('.map-marker__new')
      elem.remove()
      if(this.editLocate.mo_id){
        this.addressList.push(this.editLocate)
        setTimeout(()=>{
          this.editLocate = {}
        },500)
      }
    },
    editAddress(data){
      this.row = data
      this.mode = data
      this.modalOffice = true
    },
    addPointer(){
      this.addingPointer = true
      this.row = {
        mo_is_main : 'N',
        mo_is_active: 'Y'
      }
      this.mode = 'add'
    },
    setIsMain(data){
      if(data == 'Y') this.row.mo_is_main = 'N'
      else if(data == 'N') this.row.mo_is_main = 'Y'
    },
    savePos(){
      this.modalOffice = true
      let pos = document.querySelector(".map-marker__new").getAttribute("style")
      let split = pos.split(/;|:/)
      this.row.mo_position = {
        "top" : split[1],
        "left" : split [3]
      }
    },
    doSubmitMap(id, IdFormSlider = 'VForm') {
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: this.mode == 'add' ? 'add' : 'update'
        }, this.row),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true
            this.apiGet()
            
            // this.row = {
            //   mo_is_main : 'N',
            //   mo_is_active: 'Y'
            // }
            this.modalOffice = false
            this.addingPointer = false

            let elem = document.querySelector('.map-marker__new')
            if(this.mode == 'add'){
              elem.remove()
              if(Object.keys(resp.data.rowAlgoliaID).length){
                this.submitCRUDToAlgolia('office_id_',resp.data.rowAlgoliaID,true)
              }
              if(Object.keys(resp.data.rowAlgoliaEN).length){
                this.submitCRUDToAlgolia('office_en_',resp.data.rowAlgoliaEN,true)
              }
            }
            else{
              if(Object.keys(resp.data.rowAlgoliaID).length){
                this.submitCRUDToAlgolia('office_id_',resp.data.rowAlgoliaID,false,this.mode)
              }
              if(Object.keys(resp.data.rowAlgoliaEN).length){
                this.submitCRUDToAlgolia('office_en_',resp.data.rowAlgoliaEN,false,this.mode)
              }
            
              elem.classList.remove('map-marker__new')
              
            }
            
            window.scrollTo(0, 0)
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IdFormSlider
      )

    },
    editPointer(id){
      setTimeout(()=>{
        window.scroll({
          top: 0,
          left: 0,
        })
      },100)
      this.idEdit = id
      let index = this.addressList.findIndex(v => v.mo_id == id)
      this.modalOffice = false
      this.addingPointer = true
      this.editLocate = this.addressList[index]
      let el = document.getElementById(this.editLocate.mo_id+"-office")
      el.classList.add("map-marker__new")
      
      // this.addressList.splice(index, 1)
    },
    doFilterProvince(){
      this.filter.province = this.filterProvince
      this.doFilter()
    }
  },
  watch: {
    $route() {
      this.apiGet()
      let elem = document.getElementById('resetBtn')
      elem.remove()
    }
  }
}
</script>